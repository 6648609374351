<template>
  <div>
    <EmergencyWarning />
  </div>
</template>

<script>
import EmergencyWarning from '@/components/Emergency/EmergencyWarning.vue';

export default {
  name: 'EmergencyWarningPage',
  components: {
    EmergencyWarning,
  },
};
</script>
